<template>
  <NuxtLayout>
    <view class="lists grid lg:grid-cols-1 gap-4 m-4">
      <view class="list rounded-lg">
        <UContainer>
          <ULandingSection
            :title="$t('servers.landing.title')"
            :description="$t('servers.landing.description')"
          >
            <UDashboardCard
              :title="$t('servers.landing.dashboard.title')"
              :description="$t('servers.landing.dashboard.description')"
              icon="i-heroicons-chart-bar"
            >
              <ULandingFAQ :items="lists">
                <template #item="{ item }">
                  <view>
                    <MDC
                      :value="item.content"
                      class="prose prose-primary dark:prose-invert max-w-none text-gray-500 dark:text-gray-400"
                    />
                    <UButton
                      class="mt-4"
                      size="sm"
                      color="red"
                      :label="item.links.label"
                      :to="item.links.to"
                    />
                  </view>
                </template>
              </ULandingFAQ>
            </UDashboardCard>
          </ULandingSection>
        </UContainer>
      </view>
    </view>
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t } = useI18n();

const lists = [
  {
    label: t("servers.lists.1.label"),
    content: t("servers.lists.1.content"),
    links: {
      label: t("servers.lists.1.links.label"),
      to: "steam://rungameid/730//+connect hvh.thunder.pub",
    },
  },
  {
    label: t("servers.lists.2.label"),
    content: t("servers.lists.2.content"),
    links: {
      label: t("servers.lists.2.links.label"),
      to: "steam://rungameid/730//+connect hvh.thunder.pub:27016",
    },
  },
  // {
  //   label: "What does “Unlimited minor & patch updates” include?",
  //   content:
  //     "Dolor dolor consectetur tempor consectetur sint ut id ex quis voluptate dolore incididunt qui mollit.",
  //   to: "123",
  // },
  // {
  //   label: "Do you offer technical support?",
  //   content: "Sint id sint incididunt culpa.",
  //   to: "123",
  // },
];
</script>
